/* Add these styles to your existing CSS file or create a new one */

.contact-form-container {
    max-width: 600px;
    /* width: 100%; */
    margin: auto;
    border-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    
   
  }
  
  .contact-form-container h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group textarea {
    resize: vertical; /* Allow vertical resizing of textarea */
  }
  
  button[type="submit"] {
    display: block;
    width: 100%;
    font-size: large;
    font-weight: bold;
    padding: 12px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #555;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .contact-form-container {
      margin: 20px;
    }
    .form-group input,
  .form-group textarea {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  }
  