/* Navbar.css */

.navbar {
  background-color: rgba(51, 51, 51, 0.6);
    color: #fff;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
  }
  
  .brand {
    font-size: 1.5rem;
  }
  
  .menu {
    display: flex;
    align-items: center;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .menu li {
    margin-right: 20px;
  }
  
  .menu a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
  
  .menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu-toggle span {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 4px 14px;
  }
  .logo img {
    width: 100px; /* Adjust the width of the logo */
  }
  
  @media (max-width: 768px) {
    .menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 90px;
      left: 0;
      width: 100%;
      background-color: rgba(51, 51, 51, 0.9);
      padding: 20px;
      box-sizing: border-box;
    }
    .logo {
      display: flex;
      justify-content: center; /* Center the logo horizontally */
      align-items: center; /* Center the logo vertically */
      flex: 1; /* Take up remaining space */
    }
    
    .logo img {
      width: 100px; /* Adjust logo width */
    }
  
    .menu.open {
      display: flex;
    }
  
    .menu-toggle {
      display: none;
    }
  }
  