.App {
  text-align: center;
}

header {
  background-color: #C4A484;
  padding: 20px 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.cover-photo {
  position: relative;
  background-image: url('./assets/front.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.cover-photo h1 {
  position: absolute;
  top: 20px; /* Adjust the distance from the top */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 3rem;
}

.categories-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.categories-row button {
  background-color: #f2f2f2;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.categories-row button.selected {
  background-color: #4caf50;
  color: white;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
}

.menu-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-item h3 {
  margin-top: 0;
}

.menu-item p {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .cover-photo {
    /* Define the styles you want for smaller devices */
    width: 100%; /* For example, make the cover photo full width */
    height: 400px; /* Maintain aspect ratio */
    /* Add any other styles you need */
  }
}